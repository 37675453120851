<template>
  <div class="header" :class="{ headerFlat: headerShow, isBg: isSponsor }">
    <div class="header-wid">
      <div class="logo" @click="$router.push('/')">
        <img v-if="baseInfo && baseInfo.length" :src="baseText('logo')" />
      </div>
      <div class="nav" v-if="menus">
        <ul>
          <li
            v-for="(item, index) in menus"
            :key="index"
            @mouseenter="enterMneu"
            @mousemove="tabSelect(item, index)"
            :class="{ hover: $route.meta.data === item.layout }"
            @click="jumpWeb(item)"
          >
            {{ item.gameTypeName }}
          </li>
        </ul>
      </div>
      <div class="login" v-if="!isLogin">
        <div class="flex-center">
          <div class="btn-log" @click="loginSend">{{ $t("logins.dl") }}</div>
          <div class="btn-reg" @click="jumpReg">{{ $t("logins.zc") }}</div>
        </div>
        <div class="login-ym" v-if="baseInfo" @click.stop="isWebUrl = true">
          {{ baseInfo[5].configValue }}
          <img src="@/assets/images/newimages/glass.webp" class="glass" />
        </div>
      </div>
      <div class="login-nav" v-if="isLogin">
        <ul class="login-nav-ul">
          <li @click="judgeMoblieOrPayPwd('/finance/deposit')">
            {{ $t("components.Header.877183-0") }}
          </li>
          <li @click="$router.push('/finance/transfer')">
            {{ $t("components.Header.877183-1") }}
          </li>
          <li @click="judgeMoblieOrPayPwd('/finance/withdrawal')">
            {{ $t("components.Header.877183-2") }}
          </li>
        </ul>
        <div class="login-info">
          <div class="photo-box" v-if="userInfo">
            <img v-lazy="userInfo.photoUrl" class="photo" />
            <span
              class="level-img"
              :style="{
                backgroundImage:
                  'url(' +
                  require('@/assets/images/mine/level/vip' +
                    userInfo.levelId +
                    '.webp') +
                  ')',
              }"
            >
            </span>
          </div>
          <div class="info" v-if="userInfo">
            <div class="info-one" @click="$router.push('/finance/wallet')">
              <span class="name">
                {{ userInfo.userName }}
              </span>
              <p class="price" v-if="accountMoney">
                总资产：
                {{
                  accountMoney.userBalance
                    ? accountMoney.userBalance.toFixed(2)
                    : "0.00"
                }}元
              </p>
            </div>
            <div class="info-two" v-if="baseInfo" @click.stop="isWebUrl = true">
              永久域名：<span class="over">{{ baseInfo[5].configValue }}</span>
              <img src="@/assets/images/newimages/glass.webp" class="glass" />
            </div>
          </div>
          <div class="login-menus">
            <ul>
              <li
                v-for="(item, index) in loginMenu"
                @click="$router.push(item.link)"
                :key="index"
              >
                <span class="icon" :class="item.icon"></span>
                <span class="text">{{ item.name }}</span>
                <span class="number"></span>
              </li>
            </ul>
            <div class="login-out" @click="loginOut">
              {{ $t("components.Header.877183-3") }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="lang" v-if="langList">
        <el-select
          class="lang-style"
          v-model="langVal"
          :placeholder="$t('components.Header.877183-4')"
          @change="tabLange"
        >
          <el-option
            v-for="item in langList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
    </div>

    <!-- 下拉导航 -->
    <div class="nav-bg" v-if="isShowSelect">
      <div class="yx-wid" @mouseleave.stop="moveOut">
        <div class="yx-wid-12" :class="{ tranSg: isShowSelect }">
          <!-- 样式1 -->
          <div
            class="nav-list"
            v-if="
              [
                'sport',
                'realbet',
                'chess',
                'gaming',
                'lottery',
                'egame',
                'fishing',
              ].includes(selectType)
            "
          >
            <div
              class="arrow prev"
              @click="arrowNavTab('prev')"
              v-if="navIndex > 0"
            ></div>
            <div
              class="arrow next"
              @click="arrowNavTab('next')"
              v-if="navIndex !== moveNavWid && isNavShow"
            ></div>
            <div class="yx-wid-12 nav-list-box" ref="targetTotal">
              <div
                class="nav-list-scroll"
                :style="{ transform: 'translateX(' + menuLeftVal + 'px)' }"
              >
                <div
                  class="nav-row"
                  v-for="(item, index) in menus[menuInd].list"
                  :key="index"
                  ref="targetGame"
                >
                  <!-- style2: item.gameType === 3 -->
                  <!-- :class="{
                style: [2, 3, 6, 7].includes(menuInd),
                style1: menuInd === 6,
              }" -->
                  <div class="right">
                    <div class="text">
                      <span>{{ item.apiTitle }}</span>
                      <p>{{ item.apiTitleEn }}</p>
                    </div>
                  </div>
                  <div class="left" @click="openGame(item)">
                    <img
                      v-if="item.pcNavbarImgUrl"
                      v-lazy="item.pcNavbarImgUrl"
                      class="img"
                    />
                    <img
                      v-else
                      src="@/assets/images/menus/menus01.webp"
                      class="img"
                    />

                    <!-- <img src="@/assets/images/menus/menus01.webp" class="img" /> -->
                  </div>
                </div>
                <div class="nav-ty-img" v-if="menuInd === 1">
                  <img src="@/assets/images/menus/04.png" />
                </div>
              </div>
            </div>
          </div>
          <!-- 合营 -->
          <div
            class="help-nav"
            v-if="selectType === 'agent' && baseInfo && baseInfo[2]"
          >
            <div class="left" style="margin: 0 auto">
              <img
                v-lazy="baseText('webJointPlanImg')"
                class="link"
                @click="jumpHelp('/agent')"
              />
              <div class="title" @click="jumpHelp('/agent')">
                <span>{{ $t("components.Header.877183-6") }}</span>
                <p>{{ $t("components.Header.877183-7") }}</p>
                <ul class="agent-ul">
                  <li>
                    <img src="@/assets/images/newimages/head02.webp" />
                    <p>精确数据</p>
                  </li>
                  <li>
                    <img src="@/assets/images/newimages/head04.webp" />
                    <p>专属APP</p>
                  </li>
                  <li>
                    <img src="@/assets/images/newimages/head05.webp" />
                    <p>超高返佣比</p>
                  </li>
                  <li>
                    <img src="@/assets/images/newimages/head06.webp" />
                    <p>最优服务</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 赞助 -->
          <div class="help-nav" v-if="selectType === 'madrid'">
            <img
              v-if="baseInfo"
              v-lazy="baseText('webSponsor')"
              class="link-img"
              @click="jumpHelp('/sponsor/madrid')"
            />
          </div>
          <!-- 优惠 -->
          <div class="help-nav" v-if="selectType === 'discount'">
            <ul class="help-nav-discount">
              <li @click="jumpHelp('/activity/discount?type=2')">
                <img
                  src="@/assets/images/newimages/head09.png"
                  class="link-img"
                />
              </li>
              <li @click="jumpHelp('/activity/discount?type=5')">
                <img
                  src="@/assets/images/newimages/head08.png"
                  class="link-img"
                />
              </li>
              <li @click="jumpHelp('/mine/details')">
                <img
                  src="@/assets/images/newimages/head07.png"
                  class="link-img"
                />
              </li>
              <li @click="jumpHelp('/inviteFriends')">
                <img
                  src="@/assets/images/newimages/head10.png"
                  class="link-img"
                />
              </li>
            </ul>
          </div>
          <!-- App -->
          <div class="help-nav" v-if="selectType === 'download'">
            <div class="help-nav-download" v-if="baseInfo && baseInfo[21]">
              <div class="row">
                <img
                  v-if="baseInfo && baseInfo[53]"
                  v-lazy="baseText('webNavImg1')"
                  class="left link-img"
                  @click="jumpHelp('/download?type=0')"
                />
                <div class="right">
                  <span class="title">IOS APP</span>
                  <QRCode :text="baseText('iosApp')" :width="88" :height="88" />
                  <p>
                    <span class="small">{{
                      $t("components.Header.877183-10")
                    }}</span>
                    <a :href="baseText('iosApp')" target="_blank">{{
                      baseText("iosApp")
                    }}</a>
                  </p>
                </div>
              </div>
              <div class="row">
                <img
                  v-if="baseInfo && baseInfo[53]"
                  v-lazy="baseText('webNavImg2')"
                  class="left link-img"
                  @click="jumpHelp('/download?type=1')"
                />
                <div class="right">
                  <span class="title">Andriod APP</span>
                  <QRCode
                    :text="baseText('androidApp')"
                    :width="88"
                    :height="88"
                  />
                  <p>
                    <span class="small">{{
                      $t("components.Header.877183-11")
                    }}</span>
                    <a :href="baseText('androidApp')" target="_blank">{{
                      baseText("androidApp")
                    }}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 客服弹窗 -->
    <div class="contact-box" v-if="jumpContact">
      <div class="contact-main">
        <img src="@/assets/images/contact01.png" />
        <div class="main">
          <h2>{{ $t("components.Header.877183-12") }}</h2>
          <p class="text-red">
            {{ $t("components.Header.877183-13") }}
          </p>
          <p>
            {{ $t("components.Header.877183-14")
            }}<span class="text-red">{{
              $t("components.Header.877183-15")
            }}</span
            >{{ $t("components.Header.877183-16") }}
          </p>
          <el-button class="link" type="primary" @click="jumpContactWeb">{{
            $t("components.Header.877183-17")
          }}</el-button>
        </div>
      </div>
    </div>
    <WebUrl v-if="isWebUrl" :visible.sync="isWebUrl" />
  </div>
</template>
<script>
import { removeToken, setToken } from "@/utils/token";
import { mapState } from "vuex";
import QRCode from "@/components/QRCode.vue";
import { v4 as uuidv4 } from "uuid";
import i18n from "@/lang";
import WebUrl from "@/components/WebUrl.vue";
export default {
  components: { QRCode, WebUrl },
  data() {
    return {
      isWebUrl: false,
      menuInd: 0,
      targetMenuInd: false,
      menuLeftVal: 0,
      helps: [
        {
          name: this.$i18n.t("helpNav.kf"),
          link: "link",
          layout: "contact",
          img: require("@/assets/images/nav01.png"),
          gameTypeName: this.$i18n.t("helpNav.kf"),
          list: [],
        },
        {
          name: this.$i18n.t("helpNav.hy"),
          link: "/agent",
          layout: "agent",
          img: require("@/assets/images/nav02.png"),
          gameTypeName: this.$i18n.t("helpNav.hy"),
          list: [],
        },
        {
          name: this.$i18n.t("helpNav.yh"),
          link: "/activity/discount",
          layout: "discount",
          img: require("@/assets/images/nav04.png"),
          gameTypeName: this.$i18n.t("helpNav.yh"),
          list: [],
        },
        {
          name: "APP",
          link: "/download",
          layout: "download",
          img: require("@/assets/images/nav05.png"),
          gameTypeName: "APP",
          list: [],
        },
      ],
      headerShow: false,
      isShowSelect: false,
      navIndex: 0,
      navTotalWid: 0,
      navWid: 0,
      isNavShow: false,
      moveNavWid: 0,
      moveYnumber: 0,
      isSponsor: false,
      selectType: "",
      isClick: false,
      loginMenu: [
        {
          name: this.$t("components.Header.877183-18"),
          icon: "sjfont-card",
          link: "/mine/userInfo",
        },
        {
          name: this.$t("components.Header.877183-19"),
          icon: "sjfont-lingdang-xianxing",
          link: "/mine/vip",
        },
        // {
        //   name: this.$t("components.Header.877183-20"),
        //   icon: require("@/assets/images/mine/icon06.png"),
        //   iconHover: require("@/assets/images/mine/icon06-1.png"),
        //   link: "/record/welfare",
        // },
        {
          name: this.$t("components.Header.877183-21"),
          icon: "sjfont-order-revision-lined",
          link: "/record/betRecord",
        },
        {
          name: this.$t("components.Header.877183-22"),
          icon: "sjfont-orderform",
          link: "/record/transRecord",
        },
        {
          name: this.$t("components.Header.877183-23"),
          icon: "sjfont-VIP",
          link: "/mail",
        },
      ],
      codeUUid: "",
      codeImg: "",
      jumpContact: false,
      isCode: false,
      menuIndex: 1,
      isLoadOver: false,
      menus: null,
      langList: null, //语言列表
      langVal: "", // 语言值
      areaList: null,
      areaVal: "",
    };
  },
  watch: {
    $route(to) {
      if (!this.menus) return;
      this.menus.forEach((item) => {
        item.isSelect = false;
        if (to.meta.data === item.layout) {
          item.isSelect = true;
          this.selectType = to.meta.data;
        }
      });
      this.watchSponsorRoute(to.meta.layout);
    },
    userInfo(val) {
      this.getArea(val);
    },
  },
  computed: {
    ...mapState([
      "isLogin",
      "userInfo",
      "accountMoney",
      "baseInfo",
      "languages",
    ]),
  },
  mounted() {
    addEventListener("scroll", this.watchScroll);
    this.watchSponsorRoute(this.$route.meta.layout);
	 this.getCaptcha();
    this.getUserBalance();
    // this.getAllPlatTypes();

    this.getApiGameCategoryList();
    this.getLang();
    this.getArea();
  },
  methods: {
    tabLange(e) {
      if (!this.isLogin) {
        this.$confirm(
          this.$t("components.Header.877183-25"),
          this.$t("components.Header.877183-26"),
          {
            confirmButtonText: this.$t("components.Header.877183-27"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }
      this.changeLanguageAndCurrenc(e, "lang");
    },
    tabArea(e) {
      if (!this.isLogin) {
        this.$confirm(
          this.$t("components.Header.877183-25"),
          this.$t("components.Header.877183-26"),
          {
            confirmButtonText: this.$t("components.Header.877183-27"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }
      this.changeLanguageAndCurrenc(e, "area");
    },
    changeLanguageAndCurrenc(val, type) {
      let params = {};
      if (type === "lang") {
        params.languageType = val;
      }
      if (type === "area") {
        params.currencyType = val;
      }
      this.$Api.changeLanguageAndCurrenc(params).then((res) => {
        if (res) {
          if (type === "lang") {
            let langtarget = this.languages.find(
              (item) => item.id === val
            ).type;
            this.$i18n.locale = langtarget;
            localStorage.setItem("lang", langtarget);
          }
          location.reload();
        }
      });
    },
    // 语言数据
    getLang() {
      this.$Api
        .getSysDictionary({ dictionaryValue: "language_type" })
        .then((res) => {
          if (res) {
            // 本地是否有设置语言
            const getlang = localStorage.getItem("lang");
            let localVal = "";
            if (getlang) {
              localVal = this.languages.find(
                (item) => item.type === getlang
              ).id;
            }
            this.langList = res.sysDictionaryList;
            // 语言初始值
            const isLang = res.sysDictionaryList.find(
              (item) => item.value === localVal
            );
            if (isLang) {
              this.langVal = localVal
                ? res.sysDictionaryList.find((item) => item.value === localVal)
                    .name
                : res.sysDictionaryList[0].name;
            }
          }
        });
    },

    // 货币数据
    getArea(val) {
      this.$Api
        .getSysDictionary({ dictionaryValue: "currency_type" })
        .then((res) => {
          if (res) {
            // 本地是否有设置语言
            const getlang = localStorage.getItem("area");
            this.areaList = res.sysDictionaryList;
            let localVal = "";
            if (val) {
              localVal = val.currencyType;
            }
            // 语言初始值
            this.areaVal = localVal
              ? res.sysDictionaryList.find((item) => item.value === localVal)
                  .name
              : res.sysDictionaryList[0].name;
          }
        });
    },
    getApiGameCategoryList() {
      // 设定导航栏字段
      const layouts = [
        {
          aid: "1",
          type: "realbet",
          link: "/game/realbet",
          name: this.$i18n.t("navList.sx"),
        },
        {
          aid: "2",
          type: "egame",
          link: "/game/egame?code=ag",
          name: this.$i18n.t("navList.dz"),
          list: [],
        },
        {
          aid: "3",
          type: "lottery",
          link: "/game/lottery",
          name: this.$i18n.t("navList.cp"),
          list: [],
        },
        {
          aid: "4",
          type: "sport",
          link: "/game/sport",
          name: this.$i18n.t("navList.ty"),
          list: [],
        },
        {
          aid: "5",
          type: "gaming",
          link: "/game/gaming",
          name: this.$i18n.t("navList.dj"),
          list: [],
        },
        {
          aid: "6",
          type: "fishing",
          link: "/game/fishing?code=bg",
          name: this.$i18n.t("navList.yl"),
          list: [],
        },
        {
          aid: "7",
          type: "chess",
          link: "/game/chess",
          name: this.$i18n.t("navList.qp"),
          list: [],
        },
      ];

      this.$Api.getApiGameCategoryList().then((res) => {
        if (res) {
          res.apiGameCategoryList.forEach((item) => {
            layouts.find((row) => {
              if (item.gameType === row.aid) {
                item.layout = row.type;
                item.link = row.link;
                // item.gameTypeName = row.name
              }
            });
          });
          this.menus = res.apiGameCategoryList.filter(
            (item) => item.gameType !== "0"
          );
          // 提取首页数据需要部分
          const homeGame = res.apiGameCategoryList.filter(
            (item) => item.gameType !== "0"
          );
          // 体育置顶
          const topObj = homeGame.find((item) => item.layout === "sport");
          const arrHomeGames = homeGame.filter((item) => item !== topObj);
          arrHomeGames.unshift(topObj);
          this.$store.dispatch("setHomeGamesAc", arrHomeGames);
          this.menus.unshift({
            gameTypeName: i18n.t("components.Header.877183-24"),
            link: "/",
            list: [],
            layout: "home",
          });
          this.menus.push(...this.helps);
          this.menus.forEach((item) => {
            layouts.find((row) => {
              if (item.gameType === row.aid) {
                item.gameTypeName = row.name;
              }
            });
          });
          this.getAllPlatTypes();
        }
      });
    },
    // 打开游戏
    openGame(val) {
      if (val.gameType === 2) {
        this.$router.push({
          path: "/game/egame",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (val.gameType === 6) {
        this.$router.push({
          path: "/game/fishing",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (!this.isLogin) {
        this.$confirm(
          this.$t("components.Header.877183-25"),
          this.$t("components.Header.877183-26"),
          {
            confirmButtonText: this.$t("components.Header.877183-27"),
            showCancelButton: false,
            customClass: "el-pop-wid",
            iconClass: "sj-login-tips",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }

      const params = {
        platType: val.platType,
        gameType: val.gameType,
        gameCode: val.gameCode,
        devices: 0,
      };
      this.$Api.getGameUrl(params).then((res) => {
        const url = JSON.parse(res.data);
        window.open(url.url, "_blank");
      });
    },
    // 导航
    async getAllPlatTypes() {
      this.$store.dispatch("setNavsValAc", false);
      if (this.menuIndex === 8 && this.menus.length && this.isLoadOver) {
        this.$store.dispatch("setNavsValAc", true);
        return;
      }
      this.isLoadOver = false;
      const params = {
        gameType: this.menuIndex,
        device: 1,
      };
      await this.$Api.getAllPlatTypes(params).then((res) => {
        let obj = [];
        if (res) {
          Object.keys(res.platTypes).map((item) => {
            obj.push(res.platTypes[item]);
          });
          obj.forEach((item) => {
            Object.assign(item, {
              icon: require("@/assets/images/gameicon/" +
                item.platType +
                ".png"),
            });
          });
          // 改造后的导航
          this.menus.forEach((item) => {
            if (Number(item.gameType) === this.menuIndex) {
              this.$set(item, "list", obj);
            }
          });
          this.menuIndex++;
          this.getAllPlatTypes();
          if (this.menuIndex === 7) {
            this.$store.dispatch("setNavsListAc", this.menus);
            this.isLoadOver = true;
          }
        }
      });
    },
    inputBlur() {
      setTimeout(() => {
        this.isCode = false;
      }, 100);
    },
    // 查看code
    inputCode() {
      this.isCode = true;
    },
    getUserBalance() {
      if (!this.isLogin) return;
      this.$Api.getUserBalance().then((res) => {
        if (res) {
          this.$store.dispatch("setAccountMoneyAc", res.userBalanceMap);
        }
      });
    },
    jumpContactWeb() {
      this.jumpContact = false;
      // const url = this.$router.resolve("/customer/main");
      window.open(this.baseInfo[6].configValue, "_blank");
    },
	 async getCaptcha() {
      this.codeUUid = uuidv4();
      await this.$Api.getCaptcha(this.codeUUid).then((res) => {
        if (res) {
          let imgUrl =
            "data:image/png;base64," +
            btoa(
              new Uint8Array(res).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
          this.codeImg = imgUrl;
        }
      });
    },
    loginSend() {
      this.$router.push("/user/login");
    },
    // tabSelectHelp(val, index) {
    //   if (val.layout) {
    //     this.isShowSelect = true;
    //   } else {
    //     this.isShowSelect = false;
    //   }
    //   this.selectType = val.layout;
    // },
    watchScroll(e) {
      const top = document.body.scrollTop || document.documentElement.scrollTop;
      if (top > 50) {
        this.headerShow = true;
      } else {
        this.headerShow = false;
      }
    },
    watchSponsorRoute(val) {
      if (val === "sponsor") {
        this.isSponsor = true;
      }
    },
    enterMneu() {
      this.isClick = false;
      this.isShowSelect = false;
    },
    tabSelect(val, index) {
      if (this.isClick) return;
      this.selectType = val.layout;
      this.menuInd = index;
      this.menuLeftVal = 0;
      this.navIndex = 0;
      if (["agent", "madrid", "discount", "download"].includes(val.layout)) {
        this.isShowSelect = true;
        console.log(this.selectType, this.isShowSelect);
        return;
      } else {
        this.isShowSelect = false;
      }
      if (this.menus[index].list && this.menus[index].list.length) {
        this.isShowSelect = true;
        const rows = this.$refs.targetGame;
        if (rows && rows.length) {
          const number = rows.length;
          // 单个宽度
          this.navWid = rows[0].clientWidth;
          // 取总长
          this.navTotalWid = this.navWid * number;
          // 取余数
          this.moveYnumber = (this.navTotalWid - 1200) % this.navWid;
          // 可移动的长度
          const length = Math.ceil((this.navTotalWid - 1200) / this.navWid);
          if (length > 0) {
            this.moveNavWid = length;
          } else {
            this.moveNavWid = 0;
          }
          // 判断是否显示左右切换
          const isShowNavTab = rows[0].clientWidth * number > 1200;
          if (isShowNavTab) {
            this.isNavShow = true;
          }
        }
      } else {
        this.isShowSelect = false;
      }
    },
    arrowNavTab(val) {
      // 上下切换
      if (val === "prev") {
        if (this.navIndex === 0) return;
        this.navIndex--;
        this.menuLeftVal = -this.navWid * this.navIndex;
      } else {
        this.navIndex++;
        if (this.moveNavWid < this.navIndex) return;
        this.menuLeftVal = -this.navWid * this.navIndex;
        // console.log(this.navIndex, 8888, this.moveNavWid);
      }
    },
    moveOut() {
      this.isShowSelect = false;
    },
    jumpWeb(val) {
      if (val.layout === "contact") {
        window.open(this.baseInfo[6].configValue);
        return;
      }
      this.$router.push(val.link);
      this.isShowSelect = false;
      this.isClick = true;
    },
    jumpReg() {
      this.$router.push("/user/register");
    },
    jumpHelp(val) {
      // 外跳客服
      if (val.linka === "link") {
        this.jumpContact = true;
        return;
      }
      if (
        !this.isLogin &&
        ["/mine/details", "/activity/bettingGift"].includes(val)
      ) {
        this.$confirm(
          this.$t("components.Header.877183-25"),
          this.$t("components.Header.877183-26"),
          {
            confirmButtonText: this.$t("components.Header.877183-27"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            // console.log(11);
          });
        return;
      }
      const url = val.linka ? val.linka : val;
      this.$router.push(url);
      this.isShowSelect = false;
    },
    loginOut() {
      this.$message({
        message: this.$t("components.Header.877183-29"),
        type: "success",
      });
      removeToken();
      location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 12;
  transition: all 0.4s;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 2;
  }

  &-wid {
    display: flex;
    align-items: center;
    width: 1300px;
    padding: 0;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    gap: 40px;
    white-space: nowrap;
  }

  .logo {
    img {
      height: 42px;
    }
  }

  .nav {
    flex: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;

    ul {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 38px;

      li {
        font-size: 16px;
        position: relative;
        color: #1d1d1f;
        height: 64px;
        display: flex;
        align-items: center;
        padding: 6px 0;
        box-sizing: content-box;
        white-space: nowrap;

        &:hover {
          color: #597ef7;
        }
      }
    }
  }

  .login {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 6px;
    .login-ym {
      color: var(--color-gray);
      font-size: 12px;
      font-family: PingFang SC;
      font-size: 12px;
      color: var(--color-gray);
      display: flex;
      align-items: center;
      cursor: pointer;
      .glass {
        width: 12px;
        height: 12px;
      }
      .over {
        width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .input {
      position: relative;
      margin-left: 10px;

      &-code {
        &:hover {
          .code {
            display: block !important;
          }
        }
      }

      input {
        background: #788b9d80;
        height: 28px;
        width: 100px;
        border-radius: 15px;
        padding: 0 10px;
        position: relative;
        z-index: 2;

        &:focus,
        &:hover,
        &.focus {
          background: #788b9d;
          color: white;
        }
      }

      .code {
        position: absolute;
        top: 28px;
        right: 0;
        left: 0;
        width: 100px;
        border-radius: 20px;
        height: 32px;
        cursor: pointer;
      }

      .pwd {
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-54%);
        font-size: 12px;
        color: white;
        z-index: 3;
      }
    }

    .btn-log,
    .btn-reg {
      align-items: center;
      background-image: var(--btn-img);
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 6px;
      box-shadow: var(--btn-show);
      color: var(--color-theme);
      display: flex;
      font-family: PingFangSC;
      font-size: 14px;
      font-weight: 500;
      height: 32px;
      justify-content: center;
      line-height: 40px;
      text-align: center;
      width: 90px;
      z-index: 250;
      cursor: pointer;
      &:hover {
        background: var(--btn-jb);
        color: white;
      }
    }

    .btn-reg {
      background: var(--btn-jb);
      color: white;
      &:hover {
        background: var(--btn-jb-f);
      }
    }
  }
}
.headerFlat {
  background: linear-gradient(
    to bottom,
    #8d9ea9,
    #d8d8d8 60%,
    rgba(192, 208, 219, 0)
  );
}

.nav-bg {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.4s;
  z-index: 1;
  .yx-wid {
    background: white;
    box-shadow: 0 16px 20px 0 rgba(0, 0, 0, 0.012);
    overflow: hidden;
    &-12 {
      &.tranSg {
        .right {
          animation: movestar 0.4s ease-in-out forwards;
        }
        .left {
          animation: movestar 0.4s ease-in-out 0.1s forwards;
        }
      }
    }
  }
}

@keyframes movestar {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.nav-list {
  padding-top: 100px;
  position: relative;
  user-select: none;
  height: 380px;

  .arrow {
    position: absolute;
    top: 55%;
    left: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    z-index: 10;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      border-right: 3px solid rgba(255, 255, 255, 0.6);
      border-bottom: 3px solid rgba(255, 255, 255, 0.6);
      top: 50%;
      left: 50%;
      transform: translate(-60%, -50%) rotate(-45deg);
    }

    &.next {
      left: auto;
      right: 0;
      margin-right: -40px;
    }

    &.prev {
      margin-left: -40px;
      transform: translate(-60%, -50%) rotate(180deg);
    }
  }

  &-box {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  &-scroll {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.4s;
  }
}

.nav-row {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  flex: none;
  gap: 30px;

  .img {
    transition: all 0.4s;
    position: relative;
    // width: 100%;
    height: 100%;

    &:hover {
      transform: scale(1.04);
    }
  }

  .left {
    height: 100%;
    flex: 1;
    overflow: hidden;
    opacity: 0;
  }

  .right {
    display: flex;
    flex-direction: column;
    opacity: 0;
    .text {
      color: var(--color-black);
      text-align: center;

      span {
        font-size: 24px;
        font-weight: bold;
        white-space: nowrap;
      }

      p {
        font-size: 12px;
        white-space: nowrap;
        color: var(--color-gray);
      }
    }
  }

  // &.style2 {
  //   margin-right: 96px;
  // }
}

.nav-ty-img {
  width: 130px;
  margin-left: 40px;
  margin-right: -40px;

  img {
    width: 100%;
  }
}

.style {
  flex-direction: column;
  width: 180px;

  .left {
    order: 2;
    width: 170px;
  }

  .link {
    display: none;
  }
}

.style1 {
  .left {
    width: 150px;
    margin-top: 10px;
  }

  .img {
    width: 100%;
  }
}

.fadeIn {
  transform: translateY(0);
}

.isBg {
  background: white;
}

.help-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 100px;

  .left {
    display: flex;
    align-items: center;

    img {
      width: 240px;
      cursor: pointer;

      &.link {
        transition: all 0.3s;

        &:hover {
          transform: scale(1.04);
        }
      }
    }

    .title {
      margin-left: 50px;
      cursor: pointer;

      span {
        font-size: 40px;
        font-weight: bold;
        color: var(--color-black);
      }

      p {
        font-size: 20px;
        margin: 10px 0 20px;
        color: var(--color-gray);
      }

      img {
        width: 400px;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 16px;

      span {
        font-size: 18px;
        font-weight: bold;
        display: block;
        margin-bottom: 10px;
      }

      img {
        width: 110px;
      }

      p {
        text-align: center;
        font-size: 12px;
        margin-top: 10px;
        line-height: 20px;
      }
    }
  }
}

.link-img {
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.04);
  }
}

.help-nav-discount {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 40px;

  li {
    display: flex;
    flex-direction: column;

    .link-img {
      width: 260px;
    }
  }
}

.help-nav-download {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .row {
    display: flex;
    align-items: center;
    margin: 0 30px;

    .left {
      width: 240px;
      margin-right: 16px;
    }

    .right {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .code {
        width: 88px;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 12px;
        white-space: nowrap;
        text-align: center;
      }

      p {
        text-align: center;
        margin-top: 8px;
        font-size: 12px;
      }

      .small {
        white-space: nowrap;
        display: block;
      }

      a {
        color: #2599f8;
      }
    }
  }
}

.login-nav {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .login-nav-ul {
    display: flex;
    align-items: center;
    border: 0.5px solid #c6caf3;
    border-radius: 35px;
    padding: 6px 16px;
    gap: 10px;

    li {
      cursor: pointer;
      font-size: 12px;
      color: var(--color-black);
    }
  }

  .login-info {
    display: flex;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
    position: relative;
    .photo-box {
      position: relative;
      width: 30px;
      height: 30px;
      margin-right: 8px;

      .level-img {
        width: 36px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        display: block;
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &:hover {
      .login-menus {
        display: block;
        opacity: 1;
        transform: scale(1);
      }
    }
    .info {
      display: flex;
      flex-direction: column;
    }
    .info-one {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .info-two {
      font-size: 12px;
      color: var(--color-gray);
      display: flex;
      align-items: center;
      cursor: pointer;
      .glass {
        width: 12px;
        height: 12px;
      }
      .over {
        width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .name {
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      color: var(--color-black);
    }

    .photo {
      width: 30px;
      height: 30px;
      margin-right: 8px;
      border-radius: 100%;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .price {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: var(--color-black);
      display: flex;
      align-items: center;

      em {
        display: block;
        width: 14px;
        height: 14px;
        margin-left: 4px;
        background: url("@/assets/images/mine/icon79.png") no-repeat center
          center;
        background-size: 100% 100%;
        transition: all 0.4s;
      }
    }
  }
}

.login-menus {
  display: none;
  position: absolute;
  top: 66px;
  left: -150px;
  width: 200px;
  background: white;
  border-radius: 6px;
  padding: 12px 24px;
  transition: all 0.4s;
  transform: scale(0.5);
  opacity: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.06);

  &::before {
    position: absolute;
    content: "";
    top: -8px;
    border: 8px solid transparent;
    border-bottom-color: white;
    border-top: 0;
    right: 30px;
  }

  &::after {
    position: absolute;
    top: -80px;
    left: 0;
    right: 0;
    height: 80px;
    content: "";
  }

  ul {
    li {
      height: 48px;
      display: flex;
      align-items: center;
      padding: 4px 0;
      color: var(--color-black);

      .icon {
        font-size: 18px;
        font-weight: 500;
        margin-right: 12px;
      }

      &:hover {
        color: var(--color-theme);
      }
    }
  }

  .login-out {
    height: 40px;
    outline: none;
    border: 1px solid #e9f5fe;
    font-size: 14px;
    color: #7981a4;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #788b9d;
    text-align: center;
    margin: 8px -10px 0;

    &:hover {
      border: 1px solid var(--color-theme);
      color: var(--color-theme);
    }
  }
}

.contact-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-main {
  width: 480px;
  border-radius: 10px;
  overflow: hidden;
  background: white;

  img {
    width: 100%;
  }

  .main {
    padding: 24px;

    h2 {
      text-align: center;
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  .link {
    border-radius: 20px;
    margin: 20px auto 0;
    display: block;
    min-width: 240px;
  }
}

/deep/.lang-style {
  margin-left: 8px;

  .el-input__inner {
    border-radius: 20px !important;
    height: 28px;
    line-height: 28px;
    width: 90px;
    font-size: 14px;
  }

  .el-input__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.agent-ul {
  display: flex;
  align-items: center;
  gap: 40px;
  overflow: hidden;
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 64px !important;
    }
    p {
      font-size: 14px !important;
      margin: 0 auto !important;
    }
  }
}
</style>
